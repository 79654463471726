<template>
  <v-app>
    <div>
      <v-row class="pl-2">
        <v-col class="pa-5" cols="12" sm="12" md="12">
          <h4>Nhập từ</h4>
          <CkContent
              validateName="question"
              :content="question.content"
              @getData="getQuestionContent"
          ></CkContent>
          <h4>Nhập đáp án</h4>
          <CkContent
              validateName="answer"
              :content="answer_content"
              @getData="getAnswerContent"
          ></CkContent>
          <!-- alert 1 -->
          <v-alert type="error" dense
                   outlined v-if="alert_1">
            quá 10 kí tự
          </v-alert>
          <!-- -->
          <div class="text-center justify-center">
            <div class="my-4">
              <v-btn
                  class="ma-2 white--text"
                  color="blue-grey"
                  @click="$router.go(-1)"
              >Hủy
              </v-btn
              >
              <v-btn color="primary" type="submit" @click="submitQuestion"
              >Lưu
              </v-btn
              >
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </v-app>
</template>
<script>
import CkContent from "@/components/ck-content";
import {
  GET_EXERCISE_DETAIL,
  GET_QUESTION_DETAIL,
  SAVE_EXERCISE_CONTENT,
  UPDATE_EXERCISE_CONTENT
} from "@/store/exercises.module";
import {mapGetters} from "vuex";

export default {
  components: {
    CkContent,
  },
  data: () => ({
    alert_1: false,
    answers: [],
    answer_content: ""
  }),
  computed: {
    ...mapGetters({
      exercise: "exerciseDetail",
      question: "questionDetail",
    }),
  },
  async created() {
    await this.$store.dispatch(GET_EXERCISE_DETAIL, {
      exercise_slug: this.$route.query.exercise_slug,
    });
    await this.$store.dispatch(GET_QUESTION_DETAIL, {
      question_id: this.$route.query.question_id,
    });
    await this.initData();
  },
  mounted() {
    if (this.$route.query.question_id !== undefined) {
      this.isSelectBox = false;
      this.isUpdate = true;
    }
  },
  methods: {
    async initData() {
      if (this.hasAnswers()) {
        this.answer_content = await this.question.answers[0].content;
      }
    },
    hasAnswers() {
      if (this.question.answers[0].id !== -1) {
        return true;
      }
      return false;

    },
    getQuestionContent(value) {
      this.question.content = value;

    },
    getAnswerContent(value) {
      this.answer_content = value;
    },
    submitQuestion() {
      if (this.isUpdate && this.hasAnswers()) {

        if(this.question.content == ""){
          this.$toasted.show("Từ không được để trống !!", {
            theme: "bubble",
            position: "top-right",
            duration : 3000
          })
        }
        else if(this.answer_content == ''){
          this.$toasted.show("Đáp án không được để trống !!", {
            theme: "bubble",
            position: "top-right",
            duration : 3000
          })
        }
        else{
          this.question.answers[0].content = this.answer_content
          this.$store
              .dispatch(UPDATE_EXERCISE_CONTENT, {
                question: this.question,
                answers: this.question.answers,
                exercise: this.exercise,
              })
              .then(() => {
                this.$router.go(-1);
              });
        }
      }
      if (!this.isUpdate) {
        if(this.question.content == ""){
          this.$toasted.show("Từ không được để trống !!", {
            theme: "bubble",
            position: "top-right",
            duration : 3000
          })
        }
        else if(this.answer_content == ''){
          this.$toasted.show("Đáp án không được để trống !!", {
            theme: "bubble",
            position: "top-right",
            duration : 3000
          })
        }
        else {
          this.answers.push({
            content: this.answer_content,
            check: 0,
          });
          let params = {
            question: this.question,
            answers: this.answers,
            exercise: this.exercise,
          }
            this.$store
                .dispatch(SAVE_EXERCISE_CONTENT, params)
                .then(() => {
                  this.$router.go(-1);
                });
        }
        // if(this.answer_content !== '' && this.question.content !== ""){
        //   this.answers.push({
        //     content: this.answer_content,
        //     check: 0,
        //   });
        //   let params = {
        //           question: this.question,
        //           answers: this.answers,
        //           exercise: this.exercise,
        //         }
        //   console.log(params,"params2")
        //   // this.$store
        //   //     .dispatch(SAVE_EXERCISE_CONTENT, params)
        //   //     .then(() => {
        //   //       this.$router.go(-1);
        //   //     });
        // }
        // else {
        //   if(this.answer_content == ""){
        //     console.log("bbbb")
        //   }
        //   if(this.question.content !== ""){
        //     console.log("aaaaaa")
        //   }
        //   // this.$toasted.success('Update tài liệu thành công')
        // }

        // this.$store
        //     .dispatch(SAVE_EXERCISE_CONTENT, {
        //       question: this.question,
        //       answers: this.answers,
        //       exercise: this.exercise,
        //     })
        //     .then(() => {
        //       this.$router.go(-1);
        //     });
      }
    },
  },
};
</script>
